<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>基本信息</h6>
            </div>
            <div class="col-12 mb-4">
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3"
                  style="width: 100px"
                >
                  <span>头像</span>
                </div>
                <div class="float-left">
                  	<van-uploader v-model="fileList" upload-text="上传头像"  :max-count="1" :max-size="6 * 1024 * 1024" :preview-full-image="false" :after-read="afterReadFront">
                      <img v-if="headPic != null && headPic != ''"
                        :src="headPic"
                        class="width-100"
                      />
                    </van-uploader>
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2"
                  style="width: 100px"
                >
                  <span>昵称</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="nicName"
                    class="form-control mr-sm-2"
                    maxlength="16"
                    type="text"
                    style="width: 302px"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3"
                  style="width: 100px"
                >
                  <span>性别</span>
                </div>
                <div class="float-left">
                	<b-radio-group>
                        <b-radio name="some-radios" v-model="sex" value="1">男</b-radio>
                        <b-radio name="some-radios" v-model="sex" value="2">女</b-radio>
                    </b-radio-group>
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2"
                  style="width: 100px"
                >
                  <span>生日</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="birthday"
                    class="form-control mr-sm-2"
                    maxlength="16"
                    type="date"
                    style="width: 302px"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2"
                  style="width: 100px"
                >
                  <span>QQ号码</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="qqNumber"
                    class="form-control mr-sm-2"
                    maxlength="16"
                    type="text"
                    style="width: 302px"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div
                  class="float-left text-right mr-3 mt-2"
                  style="width: 100px">
                  <span>微信</span>
                </div>
                <div class="float-left">
                  <input
                      v-model="weChat"
                      class="form-control mr-sm-2"
                      maxlength="16"
                      type="text"
                      style="width: 302px"
                  />
                </div>
              </div>

              <div class="clearfix mt-3">
                <b-button
                  @click="modifyUserInfo"
                  variant="success"
                  style="width: 229px; margin-left: 116px"
                  >保存</b-button
                >
              </div>
            </div>
          </div>

           <div class="row bg-white rounded ml-2 mt-4 mb-4">
            <div class="p-3 border-bottom col-12">
              <h6>账户安全</h6>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 f-16">
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left">
                                <img src="@/assets/icon/u-password.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">账户密码</div>
                            <div class="float-left ml-4 mt-2 w-80">******</div>
                            <router-link to="/user/account/password/modify" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1">修改</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link>
                        </div>
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left"> 
                                <img src="@/assets/icon/auth.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">实名认证</div>
                            <div class="float-left ml-4 w-80">
                                <span class="mt-1">未认证</span>
                                <span class="text-secondary ml-2 f-14">只有实名认证的用户才能发布土地信息哦</span>
                            </div>
                            <router-link to="/user/account/certification/personal" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1">认证</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link>
                        </div>
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left">
                                 <img src="@/assets/icon/u-qyrz-active.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">企业认证</div>
                            <div class="float-left ml-4 w-80">
                                <span class="mt-1 text-success">已认证</span>
                                <span class="text-secondary ml-2 f-14">只有企业认证才能成为合作伙伴,享受更多平台服务哦</span>
                            </div>
                          
                            <router-link to="/user/account/certification/company" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1">认证</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link>
                        </div>
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left">
                                 <img src="@/assets/icon/bind-mobile.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">绑定手机</div>
                            <div class="float-left ml-4 w-80">
                                <span class="mt-1">177****9953</span>
                                <span class="text-secondary ml-2 f-14">若手机更换或停用，请立即修改，避免账户被盗</span>
                            </div>
                            <!-- <router-link to="/user/account/mobile/bind" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1">绑定</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link> -->
                            <router-link to="/user/account/mobile/update" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1">更换</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link>
                        </div>
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left">
                                 <img src="@/assets/icon/card.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">银行卡</div>
                            <div class="float-left ml-4 w-80">
                                <span class="mt-1 ml-3">共0张</span>
                                <span class="text-secondary ml-2 f-14">仅用于提现或退款</span>
                            </div>
                            <router-link to="/user/account/bankcard" target="_blank" class="float-right">
                                <span class="text-theme f-14 mr-1 ml-3">查看</span>
                                <img src="@/assets/icon/right.png" style="width:6px;">
                            </router-link>
                        </div>
                        <div class="clearfix border-bottom pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left">
                                <img src="@/assets/icon/trans-password.png" class="u-icon ml-0"/>
                            </div>
                            <div class="float-left text-gray-777">交易密码</div>
                            <div class="float-left ml-4 w-75">
                                <span class="mt-1">未设置</span>
                                <span class="text-secondary ml-2 f-14">消费余额及提现需要验证交易密码,保障您的资金安全</span>
                            </div>
                            <div class="width-100">
                          <!-- <router-link to="/user/account/transpassword/setting" target="_blank" class="float-right">
                                    <span class="text-theme f-14 mr-1">设置</span>
                                    <img src="@/assets/icon/right.png" style="width:6px;">
                                </router-link> -->
                                <div class="clearfix float-right">
                                  <router-link to="/user/account/transpassword/modify" target="_blank">
                                    <span class="text-theme f-14 mr-1">修改</span>
                                  </router-link>
                                  <router-link to="/user/account/transpassword/find" target="_blank">
                                      <span class="text-theme f-14 mr-1">找回</span>
                                  </router-link>
                                  <img src="@/assets/icon/right.png" style="width:6px;margin-right:17px">
                                </div>
                            </div>
                        </div>
                        <div class="clearfix pt-3 pb-3 ml-3 mr-3 d-flex align-items-center">
                            <div class="float-left text-gray-777 mt-1">第三方登录方式</div>
                            <div class="float-left">
                                <img src="@/assets/icon/qq-gray.png" class="u-icon ml-2"/>
                                <img src="@/assets/icon/wechat.png" class="u-icon ml-1"/>
                                <img src="@/assets/icon/weibo-gray.png" class="u-icon ml-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
export default {
  name: "info",
  data() {
    return {
        fileList: [],
        headPic:'',
        nicName:'',
        sex:'',
        birthday:'',
        qqNumber:'',
        weChat:'',
    };
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    afterReadFront(file) {
      this.$http
          .upload(this.$api.user.upload, "uploadUserFiles", file)
          .then((res) => {
            if (res.code == 1) {
              this.headPic = res.result[0].fileUrl;
            }
          });
    },
    getUserInfo(){
      this.$http .post(this.$api.user.getUserInfo).then((res) => {
        if (res.code == 1) {
          if (res.result!=null) {
            this.headPic = res.result.headPic
            this.nicName = res.result.nicName
            this.sex = res.result.sex
            this.birthday = res.result.birthday
            this.qqNumber = res.result.qqNumber
            this.weChat = res.result.weChat
          }
        }
      });
    },
    modifyUserInfo() {
      this.$http .post(this.$api.user.modifyUserInfo,{
        param:{
          headPic:this.headPic,
          nicName:this.nicName,
          sex:this.sex,
          birthday:this.birthday,
          qqNumber:this.qqNumber,
          weChat:this.weChat
        }
      }).then((res) => {
          if (res.code == 1) {
            this.user.nickName = this.nicName;
            this.user.headPic = this.headPic;
            this.$store.commit('user/SET_MEMBER', this.user);
            this.$model.toast("修改用户信息成功");
          }
      });
    },
  },
  created() {
    this.getUserInfo();
  }
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
